.home-page {
    position: relative;
   
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .typewriter-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    border-right: .15em solid #fcfcfc;
    animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
  }
  
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #000000 }
  }

  /* CSS styles for my-div on mobile */
@media only screen and (max-width: 767px) {
  .my-div {
    font-size: 18px;
    padding: 10px;
    /* additional mobile styles */
  }
}

/* CSS styles for my-div on laptop */
@media only screen and (min-width: 768px) {
  .my-div {
    font-size: 36px;
    padding: 20px;
    /* additional laptop styles */
  }
}


@media only screen and (max-width: 767px) {
  .bg-img{
    height: 700px;
  }
}

/* CSS styles for my-div on laptop */
@media only screen and (min-width: 768px) {
  .bg-img{
    height: 500px;
  }
}
